import type { FC } from 'react';
import React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { defineMessages, injectIntl } from 'react-intl';

import StarUnfilledIcon from '@atlaskit/icon/glyph/star';
import type { PositionType } from '@atlaskit/tooltip';
import Tooltip from '@atlaskit/tooltip';

import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	STAR_BUTTON_SHORTCUT,
} from '@confluence/shortcuts';
import { StarSelectedIcon } from '@confluence/icons/entry-points/StarSelectedIcon';

import { ConditionalHiddenAction, ConditionalSubtleHoverButton } from '../PresentationalComponents';
import type { PageStarProps } from './PageStar';
import { PageStar } from './PageStar';

export type ButtonProps = {
	size?: 'small' | 'medium';
	outlineAppearance?: 'normal' | 'subtle';
	tooltipPosition?: PositionType;
	shortcut?: string;
	label?: string;
	labelledBy?: string;
	id?: string;
};

const i18n = defineMessages({
	star: {
		id: 'action-buttons.content.star.label',
		defaultMessage: 'Star',
		description:
			'Label for unfilled star icon button which indicates that the content is unstarred & stars the content when clicked',
	},
	unstar: {
		id: 'action-buttons.content.unstar.label',
		defaultMessage: 'Unstar',
		description:
			'Label for filled star icon button which indicates that the content is starred & unstars the content when clicked',
	},
});

const PageStarButtonComponent: FC<ButtonProps & PageStarProps & WrappedComponentProps> = ({
	intl,
	size,
	outlineAppearance,
	tooltipPosition,
	shortcut,
	label,
	labelledBy,
	id,
	...props
}) => {
	const spacing = size === 'small' ? 'compact' : 'default';
	const tooltipLabel = props.isStarred
		? intl.formatMessage(i18n.unstar)
		: intl.formatMessage(i18n.star);
	const tooltipContent = shortcut ? (
		<ShortcutVisualizer shortcut={shortcut} contentBefore={tooltipLabel} />
	) : (
		tooltipLabel
	);
	// We always provide "Star" here as the `aria-label` because the pressed/unpressed state is communicated by the `aria-pressed` attribute.
	const buttonLabel = label ?? intl.formatMessage(i18n.star);

	return (
		<PageStar {...props}>
			{({ toggle: toggleStar }) => (
				<>
					<Tooltip content={tooltipContent} position={tooltipPosition || 'top'} hideTooltipOnClick>
						<ConditionalHiddenAction isHidden={!props.isStarred}>
							<ConditionalSubtleHoverButton
								id={id}
								isSubtle={outlineAppearance === 'subtle'}
								data-id={props.isStarred ? 'page-unstar-button' : 'page-star-button'}
								testId={props.isStarred ? 'page-unstar-button' : 'page-star-button'}
								spacing={spacing}
								appearance="subtle"
								onClick={toggleStar}
								iconBefore={
									props.isStarred ? (
										<StarSelectedIcon label="" size={size} />
									) : (
										<StarUnfilledIcon label="" size={size} />
									)
								}
								aria-label={buttonLabel}
								aria-labelledby={labelledBy}
								aria-pressed={props.isStarred}
							/>
						</ConditionalHiddenAction>
					</Tooltip>
					{shortcut && (
						<GeneralShortcutListener accelerator={STAR_BUTTON_SHORTCUT} listener={toggleStar} />
					)}
				</>
			)}
		</PageStar>
	);
};

export const PageStarButton = injectIntl(PageStarButtonComponent);
